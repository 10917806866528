import React, { useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { getLocalPluralsFunction } from '../../logic/localization/locales';
import { AppRouter } from '../routers/app-router';

interface Props
{
	locale: string
}

enum LocalizationState
{
	loading,
	idle
}

interface State
{
	state: LocalizationState
}

/** Responsible for loading localization data */
export function LocalizedApp(props: Props)
{
	const [state, setState] = useState<State>({
		state: LocalizationState.loading
	});

	// Load the locale
	useEffect(() =>
	{
		// https://lingui.js.org/guides/dynamic-loading-catalogs.html#dynamic-loading-catalogs
		// https://www.gitmemory.com/issue/lingui/js-lingui/977/780376053
		async function loadLocale()
		{
			const { messages } = await import(`../../locales/${props.locale}/messages`);
			i18n.load(props.locale, messages); // Loads message data
			i18n.loadLocaleData(props.locale, { plurals: getLocalPluralsFunction(props.locale) }); // Loads pluralization data
			i18n.activate(props.locale);
			i18n._missing = '🚨 No translation found. 🚨';
			setState({ ...state, state: LocalizationState.idle });
		}

		loadLocale();
	}, []);

	if (state.state === LocalizationState.loading)
	{
		return null;
	}
	else
	{
		return (
			<I18nProvider i18n={i18n}>
				<AppRouter />
			</I18nProvider>

		);
	}
}
