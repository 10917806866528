import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { VideoRoot } from '../video/root';


/** Router for the main app components */
export function AppRouter()
{
	// https://reactrouter.com/web/example/nesting
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/video`} component={VideoRoot} />
			<Route>
				<div>Not found :(</div>
			</Route>
		</Switch>
	);
}
