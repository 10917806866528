// https://stevenwestmoreland.com/2018/07/creating-social-sharing-links-without-javascript.html

export enum FacebookDisplay
{
	popup = 'popup',
	page = 'page'
}

export interface FacebookShareParams
{
	/** Your app's unique identifier. See Creating an App ID. */
	app_id?: string,
	/** The URL to redirect to after a person clicks a button on the dialog. Required when using URL redirection. */
	redirect_uri?: string,
	/** Determines how the dialog is rendered. If you are using URL redirection, the display type should be page. Otherwise, the display type should be popup. */
	display: FacebookDisplay,
	/** The link attached to this post. Include open graph meta tags in the page at this URL to customize the story that is shared. */
	href: string,
	/** A hashtag specified by the developer to be added to the shared content. The hashtag should include the hash symbol (e.g. #facebook). */
	hashtag?: string,
	/** A quote to be shared along with the link, either highlighted by the user or predefined by the developer, as in a pull quote on an article. This parameter is not available for Facebook URLs. */
	quote?: string
}

// https://stackoverflow.com/questions/5478702/add-facebook-share-button-to-static-html-page
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function createFacebookShareLink(params: FacebookShareParams)
{
	let link = 'https://www.facebook.com/sharer/sharer.php?u=';
	link += encodeURIComponent(params.href);
	// let link = 'https://www.facebook.com/dialog/share?';
	// link += 'app_id=' + params.app_id;
	link += '&display=' + params.display.toString();
	// link += '&href=' + encodeURIComponent(params.href);
	if (params.redirect_uri) link += '&redirect_uri=' + params.redirect_uri;
	if (params.hashtag) link += '&hashtag=' + params.hashtag;
	if (params.quote) link += '&quote=' + params.quote;

	return link;
}

export interface LinkedInShareParams
{
	/** The url-encoded URL of the page that you wish to share. */
	url: string,
	/** The url-encoded title value that you wish you use. */
	title?: string,
	/** The url-encoded description that you wish you use. */
	summary?: string,
	/** The url-encoded source of the content (e.g. your website or application name). */
	source?: string
}

export function createLinkedInShareLink(params: LinkedInShareParams)
{
	let link = 'https://www.linkedin.com/shareArticle?mini=true';
	link += '&url=' + encodeURIComponent(params.url);
	if (params.title !== undefined) link += '&title=' + encodeURI(params.title);
	if (params.summary !== undefined) link += '&summary=' + encodeURI(params.summary);
	if (params.source !== undefined) link += '&source=' + encodeURI(params.source);

	return link;
}


export interface TwitterShareParams
{
	/** Pre-populated UTF-8 and URL-encoded Tweet text. The passed text will appear pre-selected for a Twitter user to delete or edit before posting. The length of your passed Tweet text should not exceed 280 characters when combined with any passed hashtags, via, or url parameters.*/
	text: string,
	/** A fully-qualified URL with a HTTP or HTTPS scheme, URL-encoded. */
	url?: string,
	/** Allow easy discovery of Tweets by topic by including a comma-separated list of hashtag values without the preceding # character. */
	hashtags?: string[],
	/** A Twitter username to associate with the Tweet, such as your site’s Twitter account. The provided username will be appended to the end of the Tweet with the text “via @username”. */
	via?: string
}

// https://stackoverflow.com/questions/6208363/sharing-a-url-with-a-query-string-on-twitter
export function createTwitterShareLink(params: TwitterShareParams)
{
	let link = 'https://twitter.com/intent/tweet?';
	link += 'text=' + params.text;
	if (params.url) link += '&url=' + encodeURIComponent(params.url);
	if (params.hashtags && params.hashtags.length !== 0)
	{
		link += '&hashtags=';
		for (let i = 0; i < params.hashtags.length; i++)
		{
			const ht = params.hashtags[i];
			link += ht;
			if (i !== params.hashtags.length - 1)
				link += ',';
		}
	}
	if (params.via)
		link += '&via=' + params.via;


	return link;
}

export interface WhatsappShareParams
{
	text: string
}

// https://stackoverflow.com/questions/21935149/sharing-link-on-whatsapp-from-mobile-website-not-application-for-android
export function createWhatsappShareLink(params: WhatsappShareParams)
{
	let link = 'whatsapp://send?';
	link += 'text=' + params.text;
	return link;
}

export interface MessengerShareParams
{
	link: string
}

// https://stackoverflow.com/questions/34675419/share-url-in-facebook-messenger-from-web
export function createMessengerShareLink(params: MessengerShareParams)
{
	const link = 'fb-messenger://share?link=' + encodeURIComponent(params.link);
	return link;
}
