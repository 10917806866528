import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { LocalizationRouter } from '../routers/localization-router';

function App()
{
	return (
		<BrowserRouter>
			<QueryParamProvider>
				<React.StrictMode>
					<LocalizationRouter />
				</React.StrictMode>
			</QueryParamProvider>
		</BrowserRouter>
	);
}

export default App;
