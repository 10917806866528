import React from 'react';
import styles from './intro-block.module.scss';

interface Props
{
	title: string,
	tagLine: string
}

export function VideoIntroBlock(props: Props)
{
	return (
		<div className={styles.root}>
			<h1 className={`${styles.title} txt-sec fw700`}>
				{props.title}
			</h1>
			<p className={`${styles.tagline} fw500`}>
				{props.tagLine}
			</p>
		</div>
	);
}
