import React from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { locales } from '../../logic/localization/locales';
import { LocalizedApp } from '../app/localized-app';

/** Renders all localization paths and redirects to be-nl if path was not found */
export function LocalizationRouter()
{
	const location = useLocation();

	return (
		<Switch>
			{
				Object.keys(locales).map(key =>
				{
					const locale = locales[key as keyof typeof locales];

					return (
						<Route
							key={key}
							path={'/' + locale.toLowerCase()}
						>
							<LocalizedApp locale={locale} />
						</Route>
					);
				})
			}
			<Route>
				<Redirect to={{ pathname: '/' + locales.be_nl.toLowerCase(), search: location.search }} />
			</Route>
		</Switch>
	);
}
