import React, { useEffect } from 'react';
import { AppContainer } from '../shared/layout/app-container';
import { VideoIntroBlock } from './intro-block';
import styles from './root.module.scss';
import { VideoMiddleBlock } from './middle-block';
import { StringParam, useQueryParam } from 'use-query-params';
import { t } from '@lingui/macro';

const defaultVideoUrl = process.env.PUBLIC_URL + '/video/test-video.mp4';
const defaultThumbnailUrl = process.env.PUBLIC_URL + '/images/de_mol.jpg';
const defaultFirstName = 'test';

export function VideoRoot()
{
	useEffect(() =>
	{
		document.title = 'Jouw VR Experience bij the park!';
	}, []);

	const [videoUrl] = useQueryParam('vu', StringParam);
	const [thumbnailUrl] = useQueryParam('tu', StringParam);
	// Changed 'fn' to 'fina' for firstname, because twitter cards
	// acted strange when using 'fn' as a url paramter
	const [firstName] = useQueryParam('fina', StringParam);

	// Hardcoded for now -> whenever new ips are added, might wanna change this
	const translationPrefix = 'de-mol';

	return (
		<section className={styles.root}>
			<div className={styles['white-bg']}>

			</div>

			<div className={styles.content}>
				<a
					// href={t({ id: 'shared.the-park-url' })}
					href="https://www.theparkplayground.com/nl"
					target="_blank"
					className={styles['park-logo']}
				>
					<figure>
						<img
							id={'header-logo-tp-link'}
							src="/images/ThePark_Clean_Green_Logo.svg"
							alt="The Park logo"
						/>
					</figure>
				</a>

				<AppContainer>
					<VideoIntroBlock
						title={t({ id: `${translationPrefix}.videopage.intro.title`, values: { firstName: firstName !== undefined ? firstName : defaultFirstName } })}
						tagLine={t({ id: `${translationPrefix}.videopage.intro.tagline` })}
					/>

					<VideoMiddleBlock
						videoSrc={videoUrl ? videoUrl : defaultVideoUrl}
						videoThumbnailUrl={thumbnailUrl ? thumbnailUrl : defaultThumbnailUrl}
						translationPrefix={translationPrefix}
					/>
				</AppContainer>
			</div>
		</section>
	);
}
