import { nl } from 'make-plural/plurals';

const BE_NL = 'nl-BE';

export const locales =
{
	be_nl: BE_NL
};


/** Returns the plurals function based on the locale */
export function getLocalPluralsFunction(locale: string)
{
	// https://www.gitmemory.com/issue/lingui/js-lingui/977/780376053
	switch (locale)
	{
		case BE_NL: {
			return nl;
		}
		default: {
			throw new Error(`Locale ${locale} has no plurals rule yet, please add it!`);
		}
	}
}
