import React, { useMemo } from 'react';
import { createFacebookShareLink, createLinkedInShareLink, createMessengerShareLink, createTwitterShareLink, createWhatsappShareLink, FacebookDisplay, FacebookShareParams, LinkedInShareParams, TwitterShareParams, WhatsappShareParams } from '../../logic/utils/share-link-generator';
import { DownloadIcon, FacebookIcon, IconColor, LinkedInIcon, MessengerIcon, ShareLinkIcon, TwitterIcon, WhatsappIcon } from '../shared/icons/icons';
import { Row } from '../shared/layout/row';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import styles from './middle-block.module.scss';
import { useHistory } from 'react-router-dom';

// Useful links:
// https://stackoverflow.com/questions/15508421/how-to-fill-video-element-with-poster-image-even-if-the-poster-image-is-a-differ

interface Props
{
	videoSrc: string,
	videoThumbnailUrl: string,
	translationPrefix: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function VideoMiddleBlock(props: Props)
{
	const history = useHistory();

	const href = useMemo(() =>
	{
		const host = process.env.REACT_APP_LOCAL === undefined ? window.location.host : 'spectator.theparkplayground.com';
		const protocol = process.env.REACT_APP_LOCAL === undefined ? window.location.protocol : 'https:';
		const href = protocol + '//' + host + history.location.pathname + history.location.search; //.replace(/&/g, '%26');
		return href;
	}, [history]);

	const links = useMemo(() =>
	{
		const shareMessage = t({ id: `${props.translationPrefix}.videopage.share-message` });
		const twitterShareMessage = t({ id: `${props.translationPrefix}.videopage.share-message-twitter` }); //, values: { url: encodeURIComponent(href) } });
		const theparkHashtag = t({ id: 'shared.the-park-hashtag' });


		const whatsappShareParams: WhatsappShareParams = {
			text: `${shareMessage} ${encodeURIComponent(href)}`
		};

		const facebookShareParams: FacebookShareParams = {
			display: FacebookDisplay.popup,
			href: href,
			quote: shareMessage,
			hashtag: theparkHashtag
		};

		const twitterShareParams: TwitterShareParams = {
			url: href,
			text: twitterShareMessage,
			hashtags: [theparkHashtag]
		};

		const linkedInShareParams: LinkedInShareParams = {
			url: href,
			title: shareMessage
		};

		return {
			messengerLink: createMessengerShareLink({ link: href }),
			whatsappLink: createWhatsappShareLink(whatsappShareParams),
			facebookLink: createFacebookShareLink(facebookShareParams),
			tiwtterLink: createTwitterShareLink(twitterShareParams),
			linkedInLink: createLinkedInShareLink(linkedInShareParams),
		};
	}, [props.translationPrefix, href]);

	// console.log(links);

	const clipBoardAvailable = navigator.clipboard !== undefined;

	function onClipboardClick(e: React.MouseEvent<HTMLAnchorElement>)
	{
		e.preventDefault();

		// https://stackoverflow.com/questions/49618618/copy-current-url-to-clipboard
		navigator.clipboard.writeText(href);
	}

	return (
		<div className={styles.root}>
			<article className={styles['content-container']}>
				<div className={styles['video-container']}>
					<video controls poster={props.videoThumbnailUrl}>
						<source src={props.videoSrc} />
						{'Sorry, your browser doesn\'t support embedded videos.'}
					</video>
				</div>

				<div className={styles['bottom-container']}>
					<h2 className={`${styles.title} txt-sec fw700`}>
						<Trans id={`${props.translationPrefix}.videopage.middle.title`} />
					</h2>
					<Row className={styles['share-container']}>
						<a
							className={`${styles['share-link']}`}
							href={links.facebookLink}
							target='_blank'
						>
							<FacebookIcon
								id={`${props.translationPrefix}-video-share-facebook`}
								iconColor={IconColor.secondary_dark}
							/>
						</a>
						<a
							className={`${styles['share-link']} ${styles['mobile-only']}`}
							href={links.whatsappLink}
						>
							<WhatsappIcon
								id={`${props.translationPrefix}-video-share-whatsapp`}
								iconColor={IconColor.secondary_dark}
							/>
						</a>
						<a
							className={`${styles['share-link']} ${styles['mobile-only']}`}
							href={links.messengerLink}
						>
							<MessengerIcon
								id={`${props.translationPrefix}-video-share-messenger`}
								iconColor={IconColor.secondary_dark}
							/>
						</a>
						<a
							className={`${styles['share-link']}`}
							href={links.linkedInLink}
							target='_blank'
						>
							<LinkedInIcon
								id={`${props.translationPrefix}-video-share-linkedin`}
								iconColor={IconColor.secondary_dark}
							/>
						</a>
						<a
							className={`${styles['share-link']}`}
							href={links.tiwtterLink}
							target='_blank'
						>
							<TwitterIcon
								id={`${props.translationPrefix}-video-share-twitter`}
								iconColor={IconColor.secondary_dark}
							/>
						</a>
						<>
							{
								clipBoardAvailable &&
								<a
									className={`${styles['share-link']}`}
									onClick={onClipboardClick}
								>
									<ShareLinkIcon
										id={`${props.translationPrefix}-video-share-clipboard`}
										iconColor={IconColor.secondary_dark}
									/>
								</a>
							}
						</>
						<a
							className={`${styles['share-link']}`}
							href={props.videoSrc}
							target="_blank"
							download
						>
							<DownloadIcon
								id={`${props.translationPrefix}-video-share-download`}
								iconColor={IconColor.secondary_dark}
							/>
						</a>
					</Row>

					<div className={styles['discover-container']}>
						<p className="fw500">
							<Trans id={`${props.translationPrefix}.videopage.middle.discover-our-games`} />
						</p>
						<p className="fw500">
							<Trans id={'videopage.middle.goto'} />
						</p>
						<a
							id={'video-tp-link'}
							href={t({ id: 'videopage.middle.the-park-website-url' })}
							target="_blank"
							className="txt-sec fw700"
						>
							www.theparkplayground.com
						</a>
					</div>

					<div className={styles['rating-container']}>
						<p dangerouslySetInnerHTML={{ __html: t({ id: `${props.translationPrefix}.videopage.middle.rating-disclaimer` }) }} />
						<iframe
							className={styles.jotform}
							id={t({ id: `${props.translationPrefix}.videopage.middle.jotform-iframe-id` })}
							title="Form"
							onLoad={() => window.parent.scrollTo(0, 0)}
							allowTransparency={false}
							allowFullScreen={false}
							allow="geolocation; microphone; camera"
							src={t({ id: `${props.translationPrefix}.videopage.middle.jotform-iframe-link` })}
							frameBorder={'0'}
							scrolling="no">
						</iframe>
					</div>
				</div>
			</article>
		</div>
	);
}
