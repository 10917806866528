import React, { useMemo } from 'react';
import { WrapperProps } from '../../../types/components';

interface Props extends WrapperProps { }

function Row(props: Props)
{
	const className = useMemo(() =>
	{
		let cn = 'row';
		if (props.className) cn += ' ' + props.className;
		return cn;
	}, [props.className]);

	return (
		<div
			id={props.id}
			style={props.style}
			className={className}
		>
			{props.children}
		</div>
	);
}

export { Row };
