import React, { useMemo } from 'react';
import { DefaultComponentProps } from '../../../types/components';
import styles from './icons.module.scss';

export enum IconColor
{
	primary,
	secondary,
	primary_dark,
	secondary_dark,
	primary_light,
	secondary_light
}

interface Props extends DefaultComponentProps
{
	iconColor?: IconColor
}

function getClassName(props: Props)
{
	let className = '';
	if (props.className)
		className += props.className + ' ';

	switch (props.iconColor)
	{
		case IconColor.primary: {
			className += styles.primary;
			break;
		}
		case IconColor.secondary: {
			className += styles.secondary;
			break;
		}
		case IconColor.primary_dark: {
			className += styles['primary-dark'];
			break;
		}
		case IconColor.secondary_dark: {
			className += styles['secondary-dark'];
			break;
		}
		case IconColor.primary_light: {
			className += styles['primary-light'];
			break;
		}
		case IconColor.secondary_light: {
			className += styles['primary-light'];
			break;
		}
	}

	return className;
}

export function FacebookIcon(props: Props)
{
	const className = useMemo(() => getClassName(props), [props.className, props.iconColor]);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			className={className}
			id={props.id}
			style={props.style}
		>
			<path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256s114.6 256 256 256c1.5 0 3 0 4.5-.1V312.7h-55v-64.1h55v-47.2c0-54.7 33.4-84.5 82.2-84.5 23.4 0 43.5 1.7 49.3 2.5v57.2h-33.6c-26.5 0-31.7 12.6-31.7 31.1v40.8h63.5l-8.3 64.1h-55.2v189.5C433.7 471.4 512 372.9 512 256z" />
		</svg>
	);
}

export function WhatsappIcon(props: Props)
{
	const className = useMemo(() => getClassName(props), [props.className, props.iconColor]);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			className={className}
			id={props.id}
			style={props.style}
		>
			<path d="M256 0C114.64 0 0 114.64 0 256s114.64 256 256 256 256-114.64 256-256S397.36 0 256 0zm5.43 405.05h-.07a153.18 153.18 0 0 1-73.22-18.64l-81.22 21.3 21.74-79.38a152.84 152.84 0 0 1-20.46-76.57C108.24 167.3 177 98.6 261.43 98.6a153.21 153.21 0 0 1 153.21 153.28c-.03 84.46-68.77 153.17-153.21 153.17zm.05-280.58c-70.25 0-127.38 57.1-127.41 127.3a127.05 127.05 0 0 0 19.47 67.75l3 4.82-12.87 47 48.2-12.64 4.65 2.75a127.13 127.13 0 0 0 64.82 17.75h.05c70.19 0 127.32-57.11 127.35-127.31a127.33 127.33 0 0 0-127.29-127.4zm74.9 182c-3.19 8.94-18.48 17.1-25.84 18.2a52.45 52.45 0 0 1-24.11-1.51 223 223 0 0 1-21.83-8.06c-38.4-16.58-63.48-55.24-65.4-57.79s-15.63-20.76-15.63-39.6 9.89-28.1 13.4-31.92a14 14 0 0 1 10.21-4.79c2.55 0 5.11 0 7.34.13s5.5-.89 8.61 6.57l11.8 28.41a7 7 0 0 1 .32 6.71c-1.27 2.56-5.52 8.07-9.57 13.09-1.7 2.11-3.91 4-1.68 7.81s9.92 16.37 21.3 26.52c14.63 13 27 17.07 30.79 19s6.06 1.6 8.29-1 9.57-11.17 12.12-15 5.11-3.19 8.62-1.92 22.33 10.54 26.16 12.45 6.38 2.88 7.33 4.47.96 9.32-2.23 18.26z" />
		</svg>
	);
}

export function LinkedInIcon(props: Props)
{
	const className = useMemo(() => getClassName(props), [props.className, props.iconColor]);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			className={className}
			id={props.id}
			style={props.style}
		>
			<path d="M256 0C114.637 0 0 114.637 0 256s114.637 256 256 256 256-114.637 256-256S397.363 0 256 0zm-74.391 387h-62.348V199.426h62.348zm-31.172-213.187h-.406c-20.922 0-34.453-14.402-34.453-32.402 0-18.406 13.945-32.41 35.273-32.41s34.453 14.004 34.859 32.41c0 18-13.531 32.402-35.273 32.402zM406.422 387h-62.34V286.652c0-25.219-9.027-42.418-31.586-42.418-17.223 0-27.48 11.602-31.988 22.801-1.648 4.008-2.051 9.609-2.051 15.215V387h-62.344V199.426h62.344v26.559c8.285-12.781 23.109-30.961 56.188-30.961 41.02 0 71.777 26.809 71.777 84.422zm0 0" />
		</svg>
	);
}

export function InstagramIcon(props: Props)
{
	const className = useMemo(() => getClassName(props), [props.className, props.iconColor]);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			className={className}
			id={props.id}
			style={props.style}
		>
			<path d="M305 256c0 27.063-21.937 49-49 49s-49-21.937-49-49 21.938-49 49-49 49 21.938 49 49zm65.594-86.695c-2.355-6.383-6.113-12.16-10.996-16.902a45.51 45.51 0 0 0-16.902-10.996c-5.18-2.012-12.961-4.406-27.293-5.059-15.504-.707-20.152-.859-59.402-.859s-43.902.148-59.402.855c-14.332.656-22.117 3.051-27.293 5.063a45.47 45.47 0 0 0-16.902 10.996 45.57 45.57 0 0 0-11 16.902c-2.012 5.18-4.406 12.965-5.059 27.297-.707 15.5-.859 20.148-.859 59.402l.859 59.402c.652 14.332 3.047 22.113 5.059 27.293a45.55 45.55 0 0 0 10.996 16.902 45.51 45.51 0 0 0 16.902 10.996c5.18 2.016 12.965 4.41 27.297 5.063 15.5.707 20.145.855 59.398.855l59.402-.855c14.332-.652 22.117-3.047 27.297-5.062a48.68 48.68 0 0 0 27.898-27.898c2.012-5.18 4.406-12.961 5.063-27.293.707-15.504.855-20.152.855-59.402l-.855-59.402c-.652-14.332-3.047-22.117-5.062-27.297zM256 331.484c-41.691 0-75.488-33.793-75.488-75.484s33.797-75.484 75.488-75.484 75.484 33.793 75.484 75.484-33.797 75.484-75.484 75.484zm78.469-136.312c-9.742 0-17.641-7.898-17.641-17.641s7.898-17.641 17.641-17.641 17.641 7.898 17.641 17.641-7.898 17.641-17.641 17.641zM256 0C114.637 0 0 114.637 0 256s114.637 256 256 256 256-114.637 256-256S397.363 0 256 0zm146.113 316.605c-.711 15.648-3.199 26.332-6.832 35.684-7.637 19.746-23.246 35.355-42.992 42.992-9.348 3.633-20.035 6.117-35.68 6.832-15.676.715-20.684.887-60.605.887s-44.93-.172-60.609-.887c-15.645-.715-26.332-3.199-35.68-6.832a72.02 72.02 0 0 1-26.039-16.957 72.04 72.04 0 0 1-16.953-26.035c-3.633-9.348-6.121-20.035-6.832-35.68-.723-15.68-.891-20.687-.891-60.609l.887-60.605c.711-15.648 3.195-26.332 6.828-35.684a72 72 0 0 1 16.961-26.035 72.01 72.01 0 0 1 26.035-16.957c9.352-3.633 20.035-6.117 35.684-6.832C211.07 109.172 216.078 109 256 109s44.93.172 60.605.891c15.648.711 26.332 3.195 35.684 6.824a72.06 72.06 0 0 1 26.039 16.961 72.02 72.02 0 0 1 16.953 26.035c3.637 9.352 6.121 20.035 6.836 35.684.715 15.676.883 20.684.883 60.605l-.887 60.605zm0 0" />
		</svg>
	);
}

export function MessengerIcon(props: Props)
{
	const className = useMemo(() => getClassName(props), [props.className, props.iconColor]);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			className={className}
			id={props.id}
			style={props.style}
		>
			<path d="M241.57,210.21,158.62,298l75.49-41.42L273.56,298l82.49-87.83-74.66,40.7ZM256,0C114.64,0,0,114.64,0,256S114.64,512,256,512,512,397.36,512,256,397.36,0,256,0Zm2,393a164.28,164.28,0,0,1-45.54-6.39L160.84,416V360.79c-34.58-26.31-56.66-66.39-56.66-111.27C104.18,170.25,173.05,106,258,106s153.82,64.25,153.82,143.52S343,393,258,393Z" />
		</svg>
	);
}

export function TwitterIcon(props: Props)
{
	const className = useMemo(() => getClassName(props), [props.className, props.iconColor]);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			className={className}
			id={props.id}
			style={props.style}
		>
			<path d="M256 0C114.637 0 0 114.637 0 256s114.637 256 256 256 256-114.637 256-256S397.363 0 256 0zm116.887 199.602c.113 2.52.168 5.051.168 7.594 0 77.645-59.102 167.18-167.184 167.184h.004-.004c-33.184 0-64.062-9.727-90.066-26.395a119.51 119.51 0 0 0 14.016.813c27.531 0 52.867-9.391 72.98-25.152-25.723-.477-47.41-17.465-54.895-40.812a58.48 58.48 0 0 0 11.043 1.063c5.363 0 10.559-.723 15.496-2.07-26.887-5.383-47.141-29.145-47.141-57.598l.008-.75c7.918 4.402 16.973 7.051 26.613 7.348-15.777-10.527-26.148-28.523-26.148-48.91 0-10.766 2.91-20.852 7.957-29.535 28.977 35.555 72.281 58.938 121.117 61.395-1.008-4.305-1.527-8.789-1.527-13.398 0-32.437 26.316-58.754 58.766-58.754 16.902 0 32.168 7.145 42.891 18.566 13.387-2.641 25.957-7.531 37.313-14.262-4.395 13.715-13.707 25.223-25.84 32.5 11.887-1.422 23.215-4.574 33.742-9.254-7.863 11.785-17.836 22.137-29.309 30.43zm0 0" />
		</svg>
	);
}

export function DownloadIcon(props: Props)
{
	const className = useMemo(() => getClassName(props), [props.className, props.iconColor]);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			className={className}
			id={props.id}
			style={props.style}
		>
			<path d="M256 0C114.62 0 0 114.62 0 256s114.62 256 256 256 256-114.62 256-256S397.38 0 256 0zm-61.29 194.15h32.11V127a14.61 14.61 0 0 1 14.59-14.59h29.18A14.61 14.61 0 0 1 285.18 127v67.13h32.11a8.75 8.75 0 0 1 6.32 14.8l-61.28 64.2a8.75 8.75 0 0 1-12.66 0L188.39 209a8.75 8.75 0 0 1 6.32-14.85zm201.38 131.33a20.45 20.45 0 0 1-20.43 20.42H136.34a20.45 20.45 0 0 1-20.43-20.42v-5.84a20.45 20.45 0 0 1 20.43-20.43h239.32a20.45 20.45 0 0 1 20.43 20.43z" />
		</svg>
	);
}

export function ShareLinkIcon(props: Props)
{
	const className = useMemo(() => getClassName(props), [props.className, props.iconColor]);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512.09 512.09"
			className={className}
			id={props.id}
			style={props.style}
		>
			<path d="M256.05 0C114.64 0 0 114.64 0 256.05s114.64 256 256.05 256 256-114.63 256-256S397.46 0 256.05 0zm21.34 331.2l-51.16 51.16a68.21 68.21 0 0 1-96.42-96.52l62-62a68.21 68.21 0 0 1 85-9.21 66 66 0 0 1 11.45 9.21 19.3 19.3 0 0 1 0 27.38 20 20 0 0 1-27.38 0 29.22 29.22 0 0 0-41.31 0l-62.22 62.17a29.25 29.25 0 1 0 41.36 41.36l36.79-36.83a4.87 4.87 0 0 1 5.31-1.07 82.06 82.06 0 0 0 31.18 6h1.95a4.87 4.87 0 0 1 3.46 8.33zm104.84-104.9l-62 62a68.16 68.16 0 0 1-31.62 17.92h-.53l-2.83.59-1.56.29-3.36.49h-1.66c-1.26 0-2.39.24-3.46.29l-2.92.34h-3.7a67.78 67.78 0 0 1-7.26-.68c-2-.34-3.95-.73-5.9-1.22l-2.87-.78c-1-.29-2.1-.63-3.12-1s-2.1-.73-3.12-1.17l-3.07-1.32a67.52 67.52 0 0 1-19.49-13.64 19.43 19.43 0 0 1 0-27.48 20 20 0 0 1 27.39 0 29.22 29.22 0 0 0 41.31 0l16-15.88.39-.44L354.51 199a29.25 29.25 0 0 0-41.36-41.36l-36.69 36.64a4.89 4.89 0 0 1-5.31 1.07 81.86 81.86 0 0 0-31.15-6.12h-1.81a4.89 4.89 0 0 1-3.55-8.33l51.11-51.06a68.21 68.21 0 1 1 96.46 96.46z" />
		</svg>
	);
}
